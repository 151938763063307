<template>
  <div class="order-container">
    <!-- 订单头信息 -->
    <div class="order-header">
      <h2>订单详情</h2>
      <div class="order-meta">
        <p>订单号：{{ model.PayCode }}</p>
        <!-- <p>交易单号：{{ model[TradeNoField] }}</p> -->
        <p>创建时间：{{ formatTime(model.CreateTime) }}</p>
      </div>
    </div>

    <!-- 商品清单 -->
    <div class="product-list">
      <div v-for="item in model.Goods" :key="item.id" class="product-item">
        <h3>{{ item.GoodName }}</h3>
        <div class="product-detail">
          <span>单价：¥{{ item.Price.toFixed(2) }}</span>
          <span>数量：{{ item.Quantity }}</span>
          <span class="subtotal">
            <span>小计：</span>
            <span class="del-money" v-if="item.GoodMoney != item.Money">¥{{ item.GoodMoney.toFixed(2) }}</span>
            <span style="margin-left: 10px">¥{{ item.Money.toFixed(2) }}</span>
          </span>
        </div>
      </div>
    </div>

    <!-- 支付区域 -->
    <div class="payment-box">
      <div class="payment-action">
        <div class="total-amount">
          <span>总计：</span>
          <span class="del-money" v-if="model.GoodMoney != model.Money">¥{{ model.GoodMoney.toFixed(2) }}</span>
          <span style="margin-left: 10px">¥{{ model.Money.toFixed(2) }}</span>
        </div>
        <div style="display: flex; flex-direction: column; justify-content: space-between; align-items: center">
          <div v-if="isPaying" class="countdown-tip">请在 {{ formatTime(timeLeft) }} 内完成支付</div>
          <div>
            <button v-if="model.PayState == 0" class="cancel-payment" @click="handleCancelPayment">放弃支付</button>
            <button @click="handlePayment" :disabled="timeLeft <= 0" :class="{ paying: isPaying }">
              <span v-if="isPaying">我已支付</span>
              <span v-else-if="isChecking">正在校验</span>
              <span v-else>{{ state[model.PayState] }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from "vue";
import { HttpPost } from "../src/utils/network";
import { OpenWindow } from "../src/utils/dialog";

const state = {
  0: "去支付",
  1: "已支付",
  2: "支付异常",
  3: "申请退款",
  4: "允许退款",
  5: "退款失败",
  6: "已退款",
  7: "已关闭",
};
const props = defineProps({
  PayWaitTime: { type: Number, default: 3600 },
});

// 订单数据模型
const model = defineModel();

// 支付状态
const isPaying = ref(false);
// 正在校验
const isChecking = ref(false);
const timeLeft = ref(props.PayWaitTime);
let timer = null;

// 时间格式化
const formatTime = (time) => {
  if (typeof time === "number") {
    const mins = Math.floor(time / 60);
    const secs = time % 60;
    return `${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  }
  return time.toLocaleString();
};

const checkOrderStatus = () => {
  isChecking.value = true;
  HttpPost(
    "/PayRecords/Check",
    { id: model.value.ID },
    (res) => {
      Object.assign(model.value, res.data.data);
    },
    null,
    () => (isChecking.value = false)
  );
};

const handleCancelPayment = () => {
  window.close();
};

// 支付处理
const handlePayment = () => {
  if (model.value.PayState == 0) {
    if (isPaying.value == false) {
      isPaying.value = true;
      OpenWindow("支付窗口", "/PayRecords/Pay", { id: model.value.ID });
      timer = setInterval(() => {
        if (timeLeft.value > 0) {
          timeLeft.value--;
        } else {
          clearInterval(timer);
          isPaying.value = false;
          timeLeft.value = props.PayWaitTime;
          checkOrderStatus();
        }
      }, 1000);
    } else {
      clearInterval(timer);
      isPaying.value = false;
      isChecking.value = true;
      timeLeft.value = props.PayWaitTime;
      checkOrderStatus();
    }
  }
};

// 组件卸载清除定时器
onBeforeUnmount(() => {
  if (timer) clearInterval(timer);
});
</script>

<style scoped>
.order-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 30px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.order-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.order-meta p {
  color: #666;
  margin: 8px 0;
}

.product-list {
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.product-item {
  padding: 10px;
  margin: 15px 0;
  background: #f8f9fa;
  border-radius: 8px;
}

.product-detail {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 12px;
}

.subtotal {
  color: var(--primary-color);
  font-weight: bold;
  white-space: nowrap;
}

.payment-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.total-amount {
  font-size: 18px;
}

.total-amount span {
  color: var(--primary-color);
  font-size: 24px;
}

button {
  padding: 12px 40px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s;
}

button.paying {
  background: #28a745;
}

button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

.countdown-tip {
  color: var(--primary-color);
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
}

.del-money {
  text-decoration: line-through;
}

.cancel-payment {
  background: var(--surface-500);
}

button + button {
  margin-left: 8px;
}
</style>

//此文件为自动生成，请勿修改！详情见预生成脚本：cli/prepare：6
import zcalendar from "./calendar.vue";
import zdashboard from "./dashboard.vue";
import zindex from "./index.vue";
import zline from "./line.nao.vue";
import zline2 from "./line2.nao.vue";
import zlist from "./list.vue";
import zlogin from "./login.nao.vue";
import zlogin2 from "./login2.vue";
import zorder from "./order.vue";
import zsplitview from "./splitview.vue";
import zview from "./view.vue";
import zworkflow from "./workflow.vue";

/**
 * 获取页面组件
 * @param type
 * @returns {import("vue").Component}
 */
function getPageComponent(type) {
    switch (type) {
		case "calendar":
			return zcalendar;
		case "dashboard":
			return zdashboard;
		case "index":
			return zindex;
		case "line":
			return zline;
		case "line2":
			return zline2;
		case "list":
		case "select":
			return zlist;
		case "login":
			return zlogin;
		case "login2":
			return zlogin2;
		case "order":
			return zorder;
		case "splitview":
			return zsplitview;
		case "add":
		case "edit":
		case "view":
			return zview;
		case "workflow":
			return zworkflow;
        default:
            console.warn(`未找到页面组件：${type}`);
            return null;
    }
}

export {
    getPageComponent
}
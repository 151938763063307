<template>
    <div class="z-pages-login">
        <div class="login">
            <t-form labelWidth="0">
                <t-form-item>
                    <h2 class="title" v-text="Welcome"></h2>
                </t-form-item>
                <t-form-item>
                    <t-input placeholder="用户名" v-model="account" clearable @change="onChangeAccount">
                        <template #prefix-icon>
                            <UserIcon />
                        </template>
                    </t-input>
                </t-form-item>
                <t-form-item>
                    <t-input placeholder="密码" v-model="passwd" type="password" show-password clearable>
                        <template #prefix-icon>
                            <LockOnIcon />
                        </template>
                    </t-input>
                </t-form-item>
                <t-form-item>
                    <t-button :theme="state == 2 ? 'success' : 'primary'" size="large" @click="onLogin"
                              :loading="state == 1">
                        <span>登录</span>
                        <span v-text="['', '中', '成功'][state]"></span>
                    </t-button>
                </t-form-item>
                <t-form-item>
                    <t-link theme="primary" @click="onRegEnterprise">企业注册</t-link>
                </t-form-item>
                <input type="submit" @click="onSubmit" style="display: none;" />
            </t-form>
        </div>
    </div>
</template>

<script setup>
import { LockOnIcon, UserIcon } from 'tdesign-icons-vue-next';
import { getCurrentInstance, onMounted, ref } from 'vue';
import { toFn } from '../src/utils/common';

import { useUserStore } from "../src/stores/useUserStore";
import { ViteSetting } from '../src/config/vitesetting';
import { useAppStore } from '../src/stores/useAppStore';

const props = defineProps({
    /** 欢迎词 */
    Welcome: { type: String, default: "欢迎使用智合诚管理软件" },
    /** 注册企业按钮事件 */
    RegisterEnterprise: String || Function
});

/** 账号 */
const account = ref("");
/** 密码 */
const passwd = ref("");
/** 登录状态 
 * 0: 未登录
 * 1: 登录中
 * 2: 登录成功
*/
const state = ref(0);

/** 账号改变事件 */
function onChangeAccount() {
    // 清空密码
    passwd.value = "";
}

/** 登录按钮事件 */
function onLogin() {
    state.value = 1;
    useUserStore().login(account.value, passwd.value)
        .then(() => {
            state.value = 2;
            setTimeout(() => {
                if (top != window)
                    top.location.reload();
                else if (ViteSetting.IsApp)
                    location.href = `?app=${encodeURIComponent("/Home/Index")}`;
                else
                    location.href = "/Home/Index";
            }, 500);
        }).catch(() => state.value = 0);
}

/** 注册企业按钮事件 */
function onRegEnterprise() {
    toFn(props.RegisterEnterprise)();
}

/** 提交
 * @param {Event} e 
 */
function onSubmit(e) {
    onLogin();
    e.preventDefault();
}

onMounted(() => {
    useAppStore().__bindInstance(getCurrentInstance());
});
</script>

<style scoped>
.z-pages-login {
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    background-image: url("../../images/login-bg.jpg");
    display: flex;
    align-items: center;
    justify-content: center;
}

.login {
    width: 350px;
    background-color: var(--color-bg-white);
    display: flex;
    flex-direction: column;
    padding: 30px 20px 0 20px;
    border: 1px solid var(--td-border-color-light);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.title {
    width: 100%;
    text-align: center;
    color: var(--text-color);
}

.t-form__item {
    margin-bottom: var(--td-comp-margin-l);
}

.t-button {
    width: 100%;
}
</style>
<template>
    <z-progress v-if="columnConfig.Type == 'process'" v-model="model" v-bind="columnConfig" Height="18"></z-progress>
    <i v-else-if="columnConfig.Type == 'switch'" :class="model"></i>
    <template v-else-if="columnConfig.Type == 'checkbox' && columnConfig.IsSingle">
        <i :class="model[0]"></i><span :class="{ 'z-overflow': showOverflow }" v-text="model[1]"></span>
    </template>
    <template v-else-if="columnConfig.Type == 'image'">
        <t-image v-if="model" :src="model" fit="contain">
            <template #error>
                <ImageErrorIcon></ImageErrorIcon>
                <span style="margin-left: 4px;" :class="{ 'z-overflow': showOverflow }">加载失败</span>
            </template>
        </t-image>
    </template>
    <template v-else-if="setting.component.type.file.indexOf(columnConfig.Type) > -1">
        <a href="javascript:void(0);" @click="e_open" :class="{ 'z-overflow': showOverflow }">{{ model }}</a>
    </template>
    <span :class="{ 'z-overflow': showOverflow }" v-else-if="columnConfig.ViewRender || columnConfig.Type == 'template'" v-html="model"></span>
    <span :class="{ 'z-overflow': showOverflow }" v-else v-text="model"></span>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import zProgress from "../../form/progress.vue";
import { GetFileName, HasContent, MoneyFormat, RegisterEvent, toFn } from "../../../utils/common";
import { ZTableProperty } from "../../../models/ZTableProperty";
import { ZTableColumnProperty } from "../../../models/ZTableColumnProperty";
import { join } from "lodash";
import { dayjs } from "element-plus";
import { setting } from "../../../config/appsetting";
import { GetEnumOptions } from "../../../hooks/useEnumComponent";
import { ImageErrorIcon } from "tdesign-icons-vue-next";
import { TableColumnReadonly } from "./action";
import { bindDefault } from "../../../hooks/useInputComponent";
import { OpenWindow, OpenDialog } from "../../../utils/dialog";

/**
 * @type {{
 *     table: import("vxe-table").VxeTableInstance,
 *     column: import("vxe-table").VxeColumnProps,
 *     columnIndex: Number,
 *     row: import("vxe-table").VxeTableDataRow,
 *     rowIndex: Number,
 *     isHidden: Boolean,
 *     hasFilter: Boolean,
 *     seq: Number
 * }}
 */
const props = defineProps({
    table: Object,
    column: Object,
    columnIndex: { type: Number, default: null },
    row: Object,
    rowIndex: { type: Number, default: null },
    isHidden: { type: Boolean, default: null },
    hasFilter: { type: Boolean, default: null },
    seq: { type: Number, default: null }
});

/**
 * @type {import("vue").ComputedRef<ZTableProperty>}
 */
const tableConfig = computed(() => props.table.props.params);
/**
 * @type {import("vue").ComputedRef<ZTableColumnProperty>}
 */
const columnConfig = computed(() => props.column.params);

const inputValue = computed(() => props.row[columnConfig.value.Field]);

/** 打开文件
 */
function e_open() {
    (columnConfig.value.ViewNewPage ? OpenWindow : OpenDialog)("查看文件", inputValue.value);
}

/** 枚举映射 */
const enumMap = ref({});

const showOverflow = computed(() => {
    if (props.column.showOverflow === false || props.table.props.showOverflow === false)
        return false;
    return true;
});
/** 实际渲染的值
 * @author anturin
 */
const model = computed(() => {
    const table = tableConfig.value,
        column = columnConfig.value;
    if (HasContent(column.ViewRender)) {
        return toFn(column.ViewRender)(inputValue.value, table, column, props.row);
    }
    switch (column.Type) {
        case "checkbox":
            {
                if (column.IsSingle) {
                    let res = [];
                    if (inputValue.value === true)
                        res.push('tur-icon-check z-view-success');
                    else {
                        res.push('tur-icon-move z-view-error');
                    }
                    if (column.OptionRender) {
                        res.push(toFn(column.OptionRender).call({
                            get data() { return props.row; }
                        }, column));
                    }
                    return res;
                } else {
                    return String(inputValue.value.map(x => enumMap.value[x]));
                }
            }
        case "switch":
            {
                if (inputValue.value === true)
                    return 'tur-icon-check z-view-success';
                else if (column.DiscernNull !== false) {
                    return 'tur-icon-move z-view-error';
                }
                return "";
            }
        case "number":
            {
                if (isNaN(inputValue.value))
                    return inputValue.value;
                else {
                    if (HasContent(column.Precision)) {
                        let pow = Math.pow(10, column.Precision);
                        return String(Math.round(Number(inputValue.value) * pow) / pow);
                    }
                    else
                        return String(Number(inputValue.value));
                }
            }
        case "money":
            {
                if (isNaN(inputValue.value))
                    return inputValue.value;
                else
                    return MoneyFormat(Number(inputValue.value), column.Precision);
            }
        case "template":
            {
                return RegisterEvent(props.table, "RenderTemplate", () => column.Render ? toFn(column.Render)(props.row) : inputValue.value, table, column, props.row);
            }
        case "select":
            {
                if (HasContent(inputValue.value)) {
                    if (HasContent(enumMap.value))
                        return enumMap.value[inputValue.value] || "";
                    else
                        return inputValue.value || "";
                }
                return "";
            }
        case "image":
            {
                return inputValue.value || "";
            }
        case "tag":
            {
                return join(inputValue.value, "、");
            }
        case "popup":
            {
                if (columnConfig.value.ShowCode || columnConfig.value.OnlyName)
                    return inputValue.value;
                else
                    return props.row[columnConfig.value.NameField];
            }
        default:
            if (setting.component.type.file.includes(column.Type)) {
                return GetFileName(inputValue.value);
            }
            let index = setting.component.type.date.indexOf(column.Type);
            if (index > -1) {
                if (HasContent(inputValue.value)) {
                    return dayjs(inputValue.value).format(column.Format || setting.component.default.date[index]);
                } else
                    return inputValue.value;
            }
            return inputValue.value;
    }
});

onMounted(() => {
    if (["select", "radio", "checkbox"].includes(columnConfig.value.Type)) {
        GetEnumOptions(columnConfig.value).then(({ map }) => {
            enumMap.value = map.dic;
        });
    }
    if (TableColumnReadonly(props.table.props.params, columnConfig.value, props.row) === false) {
        bindDefault([columnConfig.value], props.row);
    }
});
</script>

<style scoped>
.z-view-success {
    color: var(--td-success-color);
    font-size: 1.6em !important;
    font-weight: bolder;
}

.z-view-error {
    color: var(--td-error-color);
    font-size: 1.6em !important;
    font-weight: bolder;
}

.z-overflow {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
<template>
  <t-tag-input v-model="inputValue" size="small" @change="onChange" clearable>
  </t-tag-input>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from "vue";

const props = defineProps({
  Type: String,
  Field: String,
  Readonly: { type: Boolean, default: null },
});

const emits = defineEmits(["change"]);
const model = defineModel({ type: Array, default: [] });
const inputValue = ref([]);

/** 变更事件 */
function onChange(value) {
  let oldValue = model.value;
  model.value = value;
  emits("change", model.value, oldValue);
}

const stopWatchs = [
  watch(
    () => model.value,
    (value) => {
      if (value) {
        inputValue.value = [...value];
      } else {
        inputValue.value = [];
      }
    },
    { immediate: true, deep: true }
  ),
];

onBeforeUnmount(() => {
  stopWatchs.forEach((stop) => stop());
});
</script>

import { useDialogStore } from "../stores/useDialogStore";
import { message as AntMessage, notification as AntNotification } from "ant-design-vue";
import { Drawer as ArcoDrawer, Modal as ArcoModal } from "@arco-design/web-vue";
import { HasContent, IsNullOrEmpty, MatchOption, RegisterEvent, toFn } from "./common";
import { h, nextTick, reactive, ref } from "vue";
import { ViteSetting } from "../config/vitesetting";
import { CreateFormByData, CreateURL, HttpGet, HttpPost } from "./network";
import { useContextMenuStore } from "../stores/useContextMenuStore";
import { ZDialogOption } from "../models/ZDialogOption";
import zFrame from "../components/base/frame.vue";
import { useNetworkStore } from "../stores/useNetworkStore";
import { useAppStore } from "../stores/useAppStore";
import { remove, uniqueId } from "lodash";
import { VXETable } from "vxe-table";

import dialogHeader from "../components/windows/modal/modal-header.vue";
import dialogBody from "../components/windows/modal/modal-body.vue";
import dialogLoading from "../components/windows/modal/modal-loading.vue";
import dialogFooter from "../components/windows/modal/modal-footer.vue";
import EditPanel from "../components/base/edit-panel.vue";
import { parse } from "qs";
import { stringify } from "qs";
import { PageConfig } from "../config/pageconfig";
import { LoadConfig } from "./business";

/** 吐司面包提示，不需要管理状态
 * @param {string} message
 * @param {"default" | "success" | "info" | "warning" | "error"} type
 * @param {number} duration
 * @param {import("ant-design-vue").MessageArgsProps} option
 */
function Toast(message, type, duration, option) {
  return AntMessage.open({
    type: type,
    content: message,
    duration: HasContent(duration) ? duration / 1000 : 1.5,
    ...option,
  });
}

/** 吐司消息提示，不需要管理状态
 * @param {string} message
 * @param {"default" | "success" | "info" | "warning" | "error"} type
 * @param {number} duration
 * @param {import("ant-design-vue/es/notification").NotificationArgsProps} option
 */
function Notification(title, message, type, duration, option) {
  let fn;
  switch (type) {
    case "success":
    case "error":
    case "info":
    case "warning":
      fn = AntNotification[type];
      break;
    case "default":
    default:
      fn = AntNotification.open;
      break;
  }
  if (option && option.Buttons && option.Buttons.length > 0) {
    option.footer = h(
      "div",
      {
        style: {
          display: "flex",
          justifyContent: option.footerAlign || "center",
        },
      },
      option.Buttons.map((btn) => {
        return h(
          "t-button",
          {
            theme: btn.Style || "primary",
            variant: "text",
            onClick: btn.Click,
          },
          [btn.Text]
        );
      })
    );
  }
  fn({
    message: title,
    description: message,
    duration: HasContent(duration) ? duration : 1500,
    ...option,
  });
}

/** 弹出对话框
 * @param {string} title
 * @param {string} message
 * @param {Array[]} buttons,
 * @param {(btn:Object)=>void} callback
 * @param {{
 *  type: "default" | "success" | "info" | "warning" | "error",
 *  single: Boolean,
 *  closable: Boolean,
 *  maximize: Boolean,
 *  width: Number | String,
 *  minWidth: Number | String,
 *  height: Number | String,
 *  minHeight: Number | String,
 *  maxHeight: Number | String,
 *  header: (modal)=>void,
 *  body: (modal)=>void,
 *  footer: (modal)=>void,
 *  footerAlign: "left" | "center" | "right"
 * }} option
 */
function openVxeModal(title, message, buttons, callback, option) {
  option = option || {};
  var id = uniqueId("vxe-modal-");
  VXETable.modal.open({
    id: id,
    destroyOnClose: true,
    zIndex: 2000,
    position: option.position,
    width: option.width || "60%",
    height: option.height || "auto",
    showFooter: buttons && buttons.length > 0,
    transfer: true,
    slots: {
      header({ $modal }) {
        if (option.header) return option.header($modal);
        else
          return h(dialogHeader, {
            title,
            modal: $modal,
            type: option.type,
            single: option.single,
            closable: option.closable,
            maximize: option.maximize,
            minWidth: option.minWidth,
          });
      },
      default({ $modal }) {
        if (option.body) return option.body($modal);
        else
          return h("span", {
            innerHTML: message,
            style: {
              marginTop: "0.5em",
              marginLeft: "2em",
              marginBottom: "1.5em",
              fontSize: "16px",
              minHeight: option.minHeight,
              maxHeight: option.maxHeight || "80%",
            },
          });
      },
      footer({ $modal }) {
        if (option.footer) return option.footer($modal);
        else return h(dialogFooter, { modal: $modal, buttons, align: option.FooterAlign || "right" });
      },
    },
    onClose({ $modal, btn }) {
      var next = toFn(callback)(btn, { ...arguments[0] }, $modal);
      if (next === false) {
        //阻止关闭
      } else if (next instanceof Promise) {
        if (btn) btn.loading = true;
        buttons.forEach((x) => {
          if (x != btn) x.disabled = true;
        });
        next
          .then((value) => {
            if (value === false) {
              //异步阻止关闭
            } else {
              $modal.close();
            }
          })
          .catch((error) => {
            //报错统一关闭
            console.error(error);
            $modal.close();
          });
      } else {
        $modal.close();
      }
    },
  });
  return id;
}

/**
 * @param {string} text
 * @param {import("tdesign-vue-next").ButtonProps} option
 * @returns {import("tdesign-vue-next").ButtonProps}
 */
function toButton(text, option) {
  return reactive({
    content: text,
    loading: false,
    disabled: false,
    ...option,
  });
}

/** 弹出提示框
 * @param {string} message
 * @param {"default" | "info" | "warning" | "error" | "success"} type
 * @param {function} callback
 */
function Alert(message, type, callback) {
  if (IsNullOrEmpty(message)) {
    toFn(callback)();
    return;
  }
  openVxeModal(type == "error" ? "错误警告" : type == "warning" ? "注意提醒" : "提示", message, [toButton("我知道了")], callback, {
    type,
    single: true,
    closable: false,
    maximize: false,
    minWidth: "400px",
    width: "fit-content",
  });
}

/** 弹出提示框
 * @param {string} message
 * @param {"default" | "info" | "warning" | "error" | "success"} type
 * @param {import("ant-design-vue").ModalFuncProps} option
 * @param {function} callback
 */
function AlertExtend(message, type, option, callback) {
  if (IsNullOrEmpty(message)) {
    toFn(callback)();
    return;
  }
  let table = _convertToTable(option),
    data = { [table.Field]: option.data };
  openVxeModal(type == "error" ? "错误警告" : type == "warning" ? "注意提醒" : "提示", message, [toButton("我知道了")], callback, {
    type,
    single: true,
    closable: false,
    width: option.width || "60%",
    height: option.height || "50%",
    body() {
      return h(dialogBody, { message, table, data });
    },
  });
}

/** 弹出确认框
 * @param {string} message
 * @param {function} callback
 * @param {import("primevue/confirmationoptions").ConfirmationOptions} option
 */
function Confirm(message, callback, option) {
  option = option || {};
  var target = option.target;
  if (window.event && window.event.currentTarget && window.event.currentTarget instanceof HTMLButtonElement) {
    target = window.event.currentTarget;
    const store = useDialogStore();
    if (store.PrimePopupConfirm) {
      store.PrimePopupConfirm.require({
        target: target,
        title: "提示",
        message: message,
        acceptLabel: option.confirmText || "确定",
        rejectLabel: option.cancelText || "取消",
        accept() {
          toFn(callback)({ confirm: true, cancel: false, close: false });
        },
        reject: () => {
          toFn(callback)({ confirm: false, cancel: true, close: false });
        },
        onHide() {
          toFn(callback)({ confirm: false, cancel: false, close: true });
        },
      });
    }
  } else {
    let confirmText = option.confirmText || "确定",
      cancelText = option.cancelText || "取消";
    openVxeModal(
      "提示",
      message,
      [toButton(cancelText, { variant: "outline" }), toButton(confirmText)],
      (btn) => {
        return toFn(callback)({
          confirm: btn && btn.content == confirmText,
          cancel: btn && btn.content == cancelText,
          close: btn === undefined,
        });
      },
      {
        single: true,
        type: "warning",
        maximize: false,
        minWidth: "400px",
        width: "fit-content",
        closable: true,
      }
    );
  }
}

/** 转换为表格
 * @param {{config:Object,chinese:Object,data:Array}} option
 */
function _convertToTable(option) {
  let table;
  if (option.config) table = option.config;
  else {
    if (option.chinese) {
      table = {
        PageMode: false,
        Columns: Object.entries(option.chinese).map(([key, value]) => ({ Label: value, Field: key })),
      };
    } else if (option.data && option.data.length > 0) {
      table = {
        PageMode: false,
        Columns: Object.entries(option.data[0]).map(([key, value]) => ({ Label: value, Field: key })),
      };
    }
  }
  if (HasContent(table)) {
    if (IsNullOrEmpty(table.Field)) table.Field = "SHOW_TABLE_DATA";
    return table;
  } else return {};
}

/** 弹出确认框
 * @param {string} message
 * @param {function} callback
 * @param {import("primevue/confirmationoptions").ConfirmationOptions} option
 */
function ConfirmExtend(message, callback, option) {
  option = option || {};
  let table = _convertToTable(option),
    data = { [table.Field]: option.data },
    confirmText = option.confirmText || "确定",
    cancelText = option.cancelText || "取消";
  openVxeModal(
    "提示",
    message,
    [toButton(cancelText, { variant: "outline" }), toButton(confirmText)],
    (btn) => {
      return toFn(callback)({
        confirm: btn && btn.content == confirmText,
        cancel: btn && btn.content == cancelText,
        close: btn === undefined,
      });
    },
    {
      single: true,
      type: "warning",
      width: option.width || "60%",
      height: option.height || "50%",
      closable: true,
      body() {
        return h(dialogBody, { message, table, data });
      },
    }
  );
}

/** 弹出加载框
 * @param {string} message
 * @param {HTMLElement} target
 */
function Loading(message, target) {
  let id = uniqueId("loading-"),
    content = ref(message);
  VXETable.modal.open({
    id,
    showHeader: false,
    showFooter: false,
    className: "z-loading",
    width: "100%",
    slots: {
      default() {
        return h(dialogLoading, { message: content.value });
      },
    },
  });
  return {
    close() {
      return new Promise((resolve, reject) => {
        setTimeout(() => VXETable.modal.close(id).then(resolve).catch(reject), 100);
      });
    },
    get text() {
      return content.value;
    },
    set text(value) {
      content.value = value;
    },
  };
}

/** 打开页面
 * @param {string} title
 * @param {string} url
 * @param {Object} param
 * @param {ZDialogOption | import("@arco-design/web-vue").DrawerConfig | import("@arco-design/web-vue").ModalConfig} option
 */
function OpenPage(title, url, param, option) {
  option = option || {};
  let store = useDialogStore(),
    body = h(zFrame, { title: title, src: url, param: param }),
    page = {
      id: uniqueId("page-"),
      ...ArcoDrawer.open({
        title: title,
        height: "100%",
        width: "50%",
        content: body,
        maskClosable: false,
        placement: option.FromRight ? "right" : "bottom",
        ...option,
        footer: false,
        onClose() {
          remove(store.pages, (x) => x.id == page.id);
          toFn(page.callback).call(useAppStore());
        },
      }),
    };
  store.pages.push(page);
}

/** 关闭页面
 * @param {Function} callback 回调事件
 * @param {Number} num 关闭层数
 */
function ClosePage(callback, num) {
  if (parent == window) {
    Alert("未找到父页面的信息！", "error");
    return false;
  }
  num = num || 1;
  let win = window;
  while (num > 0) {
    //没有父页面，此页面为外层页面
    if (win.parent == win) break;
    if ("$app" in win.parent) {
      var fApp = win.parent.$app;
      if (fApp.CanClosePage()) {
        win = win.parent;
        num--;
      } else {
        break;
      }
    } else {
      break;
    }
  }
  if ("$app" in win) {
    win.$app.ClosePage(callback);
    return true;
  } else {
    Alert("父页面不是新版Web，无法关闭此页面！", "error");
    return false;
  }
}

/** 打开对话框
 * @param {string} title
 * @param {string} url
 * @param {Object} param
 * @param {function} callback
 * @param {ZDialogOption | import("@arco-design/web-vue").DrawerConfig | import("@arco-design/web-vue").ModalConfig} option
 */
function OpenDialog(title, url, param, callback, option) {
  option = option || {};
  /**
   * @type {(option:import("@arco-design/web-vue").DrawerConfig | import("@arco-design/web-vue").ModalConfig)=>void}
   */
  let fn,
    fnResult = (action) => {
      return new Promise((resolve, reject) => {
        let result = action();
        if (result === false) reject();
        else if (result instanceof Promise)
          result
            .then((res) => {
              if (res === false) reject();
              resolve(result);
            })
            .catch(reject);
        else resolve(result);
      });
    };
  if (option.FromRight) {
    fn = ArcoDrawer.open;
  } else {
    fn = ArcoModal.open;
  }
  let store = useDialogStore(),
    body = h(zFrame, { title: title, src: url, param: param }),
    dialog = {
      id: uniqueId("dialog-"),
      ...fn({
        title: title,
        width: option.width || "80vw",
        bodyStyle: {
          height: option.height || "60vh",
        },
        content: body,
        maskClosable: false,
        footer: option.OnlyShow ? false : true,
        okButtonProps: {
          size: "small",
        },
        cancelButtonProps: {
          size: "small",
        },
        onBeforeOk(done) {
          if (body.el) {
            /**
             * @type {Window}
             */
            const win = body.el.parentElement.getElementsByTagName("iframe")[0].contentWindow;
            if (win) {
              fnResult(() => toFn(win.DialogCallBack)())
                .then((res) => toFn(callback)(res))
                .then(() => done(true))
                .catch((err) => done(false));
              return;
            } else {
              Toast("请稍候，弹窗还未加载完毕！", "warning");
              return false;
            }
          } else {
            Toast("请稍候，弹窗还未加载完毕！", "warning");
            return false;
          }
        },
        onBeforeClose() {
          if ("result" in dialog) {
            var next = toFn(callback)(dialog.result);
            if (next === false) return false;
            if (next instanceof Promise) console.warn("内部强制提交的结果，不支持异步判断是否关闭，无法拦截！");
            return true;
          }
          return true;
        },
        onClose() {
          remove(store.dialogs, (x) => x.id == dialog.id);
        },
      }),
    };
  store.dialogs.push(dialog);
}

/** 关闭弹窗
 * @param {Object} res 关闭弹窗并返回的结果
 * @param {Function} callback 关闭弹窗的回调事件
 * @param {Number} num 关闭层数
 */
function CloseDialog(res, callback, num) {
  if (parent == window) {
    Alert("未找到父页面的信息！", "error");
    return false;
  }
  num = num || 1;
  let win = window;
  while (num > 0) {
    //没有父页面，此页面为外层页面
    if (win.parent == win) break;
    if ("$app" in win.parent) {
      win = win.parent;
      num--;
    } else {
      break;
    }
  }
  if ("$app" in win) {
    win.$app.CloseDialog(res, callback);
    return true;
  } else {
    Alert("父页面不是新版Web，无法关闭此页面！", "error");
    return false;
  }
}

/** 打开新窗口
 * @param {String} title 窗体标题
 * @param {String} uri 窗体地址
 * @param {Object} param 参数 $Post会触发POST打开页面
 * @param {Object} option 选项
 */
function OpenWindow(title, uri, param, op) {
  param = useNetworkStore().getBaseQuery(param);
  op = MatchOption(
    {
      width: 1200,
      height: 550,
      top: 100,
      left: 150,
      toolbar: "no",
      menubar: "no",
      resizable: "no",
      location: "no",
      status: "no",
      scrollbars: "yes",
      depended: "yes",
    },
    op
  );
  let strOption = Object.entries(op)
    .map(([key, value]) => `${key}=${value}`)
    .join(",");
  if (param.$Post) {
    let body = param.$Post,
      form = CreateFormByData(body),
      event = document.createEvent("Event");
    delete param.$Post;
    form.method = "POST";
    form.target = title;
    if (ViteSetting.IsApp) form.action = `?app=${encodeURIComponent(CreateURL(uri, param))}`;
    else form.action = CreateURL(uri, param);
    event.initEvent("onsubmit", true, true);
    form.addEventListener("onsubmit", () => window.open("about:blank", title, strOption), false);
    document.body.appendChild(form);
    form.dispatchEvent(event);
    form.submit();
    document.body.removeChild(form);
  } else {
    if (ViteSetting.IsApp) window.open(`?app=${encodeURIComponent(CreateURL(uri, param))}`, title, strOption);
    else window.open(CreateURL(uri, param), title, strOption);
  }
}

/**
 * 表单输入框
 * @param {String} title 标题
 * @param {Object} config 表单配置(参照VueEditPage)
 * @param {Object} data 表单初始数据
 * @param {function():Boolean} callback 确定回调
 * @param {ZDialogOption | import("@arco-design/web-vue").DrawerConfig | import("@arco-design/web-vue").ModalConfig} option 表单额外参数
 */
function OpenFormDialog(title, config, data, callback, option) {
  option = option || {};
  option.FooterAlign = "center";
  let store = useDialogStore(),
    fnData = reactive(data || {}),
    confirmText = option.confirmText || "确认",
    cancelText = option.cancelText || "取消",
    btnConfirm = toButton(confirmText),
    btnCancel = toButton(cancelText, { variant: "outline" }),
    buttons = [btnCancel];
  if (option.ShowSubmit !== false) buttons.push(btnConfirm);
  if ("Buttons" in option) buttons.splice(0, 0, ...option.Buttons.map((x) => toButton(x.Label, { theme: x.Style, ...x })));
  if ("Tabar" in config && "Buttons" in config.Tabar) buttons.splice(0, 0, ...config.Tabar.Buttons.map((x) => toButton(x.Label, { theme: x.Style, ...x })));
  let id = openVxeModal(
    title,
    undefined,
    buttons,
    (btn, option) => {
      if (btn) {
        if (btn == btnCancel) {
          //取消按钮不返回数据
        } else if (btn == btnConfirm) {
          if (option && option.result) {
            let next = toFn(callback)(option.result);
            if (next === false) return false;
            else if (next instanceof Promise) {
              next.then((res) => {
                if (res === false) return false;
                else return toFn(option.callback)(res);
              });
            } else {
              return toFn(option.callback)(next);
            }
          } else {
            return toFn(callback)(fnData);
          }
        } else {
          return toFn(btn)(fnData, btn);
        }
      }
    },
    {
      body() {
        var edit = h(EditPanel, { ...config, modelValue: fnData });
        if (config.message) {
          return h("div", {}, [h("p", { style: "padding:5px", innerHTML: config.message }), edit]);
        } else {
          return edit;
        }
      },
      ...option,
    }
  );
  store.formDialogs.push({ id, btn: btnConfirm });
}

/** 关闭表单输入框
 * @param {Object} res 表单结果
 * @param {function():Boolean} callback 确定回调
 */
function CloseFormDialog(res, callback) {
  useAppStore().CloseFormDialog(res, callback);
}

/** 打开右键菜单
 * @param {Event} e
 * @param {import("primevue/menuitem").MenuItem[]} value
 */
function OpenContextMenu(e, value) {
  useContextMenuStore().open(e, value);
}

/** 刷新页面，无需重新加载资源
 * @param {Object} query 额外的参数
 * @description GET请求的页面刷新，重新获取 model 的数据。
 * @description Post 请求会丢失表单数据，需要设置 $Post 传递数据
 */
function RefreshPage(query) {
  query = { ...query };
  let fn = (res) => {
    Object.entries(res.data).forEach(([key, value]) => {
      switch (key) {
        case "JavaScripts":
        case "StyleSheets":
          break;
        default:
          window[`$${key}`] = value;
          break;
      }
    });
    const app = useAppStore();
    app.pagetype = PageConfig.PageType;
    nextTick(() => {
      app.config = PageConfig.Config || {};
      app.data = PageConfig.Data || {};
      RegisterEvent(window, "PageLoad", () => {
        LoadConfig(true);
        if (HasContent(PageConfig.Data) == false && HasContent(PageConfig.DataUrl)) HttpPost(PageConfig.DataUrl, {}, (res) => Object.assign(app.data, res.data));
        if (HasContent(PageConfig.LoadMessage)) Alert(PageConfig.LoadMessage, "warning");
      });
    });
  };
  if ("$Post" in query) {
    let body = query.$Post;
    delete query.$Post;
    HttpPost(
      location.pathname +
        "?" +
        stringify({
          ...(location.search && location.search.length > 1 ? parse(location.search.substring(1)) : {}),
          ...query,
        }),
      body,
      fn
    );
  } else {
    HttpGet(
      location.pathname,
      {
        ...(location.search && location.search.length > 1 ? parse(location.search.substring(1)) : {}),
        ...query,
      },
      fn
    );
  }
}

/** 刷新数据，无需重新加载资源
 * @param {Object} query 额外的参数
 * @description GET请求的页面刷新，重新获取 model 的数据。
 * @description Post 请求会丢失表单数据，需要设置 $Post 传递数据
 */
function RefreshData(query) {
  query = { ...query };
  let fn = (res) => {
    const app = useAppStore();
    PageConfig.Data = res.data || {};
    app.data = PageConfig.Data;
  };
  if ("$Post" in query) {
    let body = query.$Post;
    delete query.$Post;
    HttpPost(
      location.pathname +
        "?" +
        stringify({
          ...(location.search && location.search.length > 1 ? parse(location.search.substring(1)) : {}),
          ...query,
          only__model: "true",
        }),
      body,
      fn
    );
  } else {
    HttpGet(
      location.pathname,
      {
        ...(location.search && location.search.length > 1 ? parse(location.search.substring(1)) : {}),
        ...query,
        only__model: "true",
      },
      fn
    );
  }
  Object.assign(PageConfig.Query, query);
}

export { Toast, Alert, AlertExtend, Confirm, ConfirmExtend, Notification, Loading, OpenPage, ClosePage, OpenDialog, CloseDialog, OpenWindow, OpenFormDialog, CloseFormDialog, OpenContextMenu, RefreshPage, RefreshData };
